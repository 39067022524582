import Vue from 'vue';
import Vuex from 'vuex';

import auth from './auth';
import shared from './shared';
import devices from './devices';
import workers from './workers';
import promts from './promts';
import promptsTools from './promts/tools';
import activity from './activity';
import customer from './customer';
import workflows from './workflows';
import credentials from './credentials';
import notifications from './notifications';
import patient from './patients';
import invoices from './invoices';
import webhooks from './webhooks';
import generatedCodes from './generated-codes';
import MLModels from './ml-models';
import AIChat from './ai-chat';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  modules: {
    auth,
    shared,
    devices,
    activity,
    customer,
    workflows,
    credentials,
    notifications,
    workers,
    promts,
    patient,
    invoices,
    webhooks,
    generatedCodes,
    MLModels,
    promptsTools,
    AIChat,
  },
});
