import clearWorkflow from "./clearWorkflow";
import fetchWorkflow from "./fetchWorkflow";
import setStartSettings from "./setStartSettings";
import workflowEdits from "./workflowEdits";
import setNodes from "./setNodes";
import setTaskInput from "./setTaskInput";
import validateActions from "./validateActions";
import startWorkflow from "./startWorkflow";
import deviceStatus from "./deviceStatus";
import getWorkflowStatus from "./getWorkflowStatus";
import saveWorkflow from "./saveWorkflow";
import updateWorkflow from "./updateWorkflow";
import fetchOutputModels from "./fetchOutputModels";
import fetchAppointmentOptions from "./fetchAppointmentOptions";
import updateNodes from "./updateNodes";
import checkVmStatus from "./checkVmStatus";
import runWorkflow from "./runWorkflow";
import executeSingleAction from "./executeSingleAction";
import nodeDrop from "./nodeDrop";
import getVideoLinks from './getVideoLinks'
import getWorkflowTask from './getWorkflowTask'
import retryActions from './retryActions'
import workflowSetup from './workflowSetup';
import getWorkflowTriggerIO from "@/store/workflows/details/actions/getWorkflowTriggerIO";

const actions = {
  clearWorkflow,
  fetchWorkflow,
  setStartSettings,
  setNodes,
  setTaskInput,
  validateActions,
  startWorkflow,
  deviceStatus,
  getWorkflowStatus,
  saveWorkflow,
  updateWorkflow,
  fetchOutputModels,
  fetchAppointmentOptions,
  updateNodes,
  checkVmStatus,
  runWorkflow,
  executeSingleAction,
  nodeDrop,
  getVideoLinks,
  getWorkflowTask,
  retryActions,
  workflowSetup,
  getWorkflowTriggerIO,
  ...workflowEdits,
};

export default actions;
