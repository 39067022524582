import getPatientBalance from './getPatientBalance';
import getPaymentHistory from './getPaymentHistory';
import getPatientMessages from './getPatientMessages';
import getPatient from './getPatient';
import setOptOut from './setOptOut';

export default {
  getPatientBalance,
  getPaymentHistory,
  getPatientMessages,
  getPatient,
  setOptOut,
};
