const fetchStandaloneInvoices = async ({ commit, rootGetters, dispatch }, options) => {
  commit('SET_FETCH_STANDALONE_INVOICES_LOADING', true);
  try {
    const customer = rootGetters['customer/CUSTOMER'];
    const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];
    let url = '/proxy/invoices';
    let customer_id = customer.customer_id;
    if (customer_id === 0) {
      customer_id = selected_customer.customer_id;
    }

    const { search, fromDate, toDate, expand, patientId } =
      options;
    url += `?customer_id=${customer_id}&page=${1}&limit=${9999}&filter=manual&status=false`;

    if (search) url += `&search=${search}`; // search is a string (patient name
    if (fromDate) url += `&fromDate=${fromDate}`;
    if (toDate) url += `&toDate=${toDate}`;
    if (expand) url += `&expand=${expand}`;
    if (patientId) url += `&patientId=${patientId}`;

    const response = await window.axios.get(url);

    if (response && response.data.success) {
      commit('SET_STANDALONE_INVOICES', response.data.invoices?.rows);
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    commit('SET_FETCH_STANDALONE_INVOICES_LOADING', false);
  }
};

export default fetchStandaloneInvoices;
