import createToolSet from "@/store/ai-chat/actions/createToolSet";
import getCurrentSession from "@/store/ai-chat/actions/getCurrentSession";
import getSessions from "@/store/ai-chat/actions/getSessions";
import getToolSets from "@/store/ai-chat/actions/getToolSets";
import getUserSettings from "@/store/ai-chat/actions/getUserSettings";
import sendMessage from "@/store/ai-chat/actions/sendMessage";
import updateUserSettings from "@/store/ai-chat/actions/updateUserSettings";
import updateSystemPrompt from "@/store/ai-chat/actions/updateSystemPrompt";
import getSystemPrompts from "@/store/ai-chat/actions/getSystemPrompts";
import createSystemPrompt from "@/store/ai-chat/actions/createSystemPrompt";
import getLastMessage from "@/store/ai-chat/actions/getLastMessage";
import updateSession from "@/store/ai-chat/actions/updateSession";

export default {
    createToolSet,
    getCurrentSession,
    getSessions,
    getToolSets,
    getUserSettings,
    sendMessage,
    updateUserSettings,
    updateSystemPrompt,
    getSystemPrompts,
    createSystemPrompt,
    getLastMessage,
    updateSession,
}