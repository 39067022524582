import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = () => ({
  workflow: {},
  selectedNode: null,
  selectedNodeOptions: null,
  startSettings: {
    period: null,
    time_at: null,
    day: null,
    week: [],
    custom: null,
    trigger_subtype: null,
    trigger_type: "cron",
  },
  s3StorageEnabled: false,
  alertSettings: {
    enabled: false,
    rateCondition: "< 90",
    status:['FAILURE'],
    pingUsers: [],
  },
  credentials: [],
  actionsOutputs: null,
  appointmentOptions: [],
  nodes: [],
  copyOfSavedNodes: [],
  movingNodeId: null,
  inputTaskQuestions: null,
  workflowEdited: false,
  runStatus: {},

  // loaders
  fetchWorkflowLoading: false,
  setInputTaskLoading: false,
  saveWorkflowLoading: false,
  updateWorkflowLoading: false,
  fetchOutputModelsLoading: false,
  fetchAppointmentOptionsLoading: false,
  startWorkflowLoading: false,
  runWorkflowLoading: false,
  checkingAutomationWorkflowStatusLoading: false,
  executeSingleActionLoading: false,
});

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
};
