import isObject from 'lodash/isObject';
import keyBy from 'lodash/keyBy';
import transform from 'lodash/transform';
import forEach from 'lodash/forEach';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';

export const actionsTypes = (name) => {
  return [
    {
      type_id: '4b19f648-6e78-4f0d-95a2-1c4abd64e304',
      action_type: 'incoming_jot_form',
      display_name: 'Incoming Jotform',
      name: 'Incoming Jot Form Message',
      descr: '',
      default: true,
      color: '#4DD08B',
      settings: {
        head: null,
        tail: null,
      },
      output: {},
      output_type: null,
      icon: 'mdi-arrow-down-bold',
    },
    {
      type_id: 'ecf655d2-8758-475e-a4bc-8cb2eb73349a',
      action_type: 'incoming_twilio',
      display_name: 'Incoming Twilio',
      name: 'Incoming Twilio Message',
      descr: '',
      default: true,
      color: '#4DD08B',
      settings: {
        head: null,
        tail: null,
      },
      output: {},
      output_type: null,
      icon: 'mdi-arrow-down-bold',
    },
    {
      type_id: 'bf5cb741-e3e9-45fb-82e2-52b95d848eef',
      action_type: 'incoming_insurance_task',
      display_name: 'Incoming Insurance Task',
      name: 'Incoming Insurance Task',
      descr: '',
      default: true,
      color: '#4DD08B',
      settings: {
        head: null,
        tail: null,
      },
      output: {},
      output_type: null,
      icon: 'mdi-arrow-down-bold',
    },
    {
      type_id: '3b0b94ec-16f0-457c-9352-3b0902e68014',
      action_type: 'incoming_dropin',
      display_name: 'Incoming DropIn',
      name: 'Incoming DropIn',
      descr: '',
      default: true,
      color: '#4DD08B',
      settings: {
        head: null,
        tail: null,
      },
      output: {},
      output_type: null,
      icon: 'mdi-arrow-down-bold',
    },
    {
      type_id: '277f6ff8-34b0-4fbd-a864-7bb4ad40952b',
      action_type: 'incoming_general',
      display_name: 'Incoming General',
      name: 'Incoming General',
      descr: '',
      default: true,
      input: {},
      color: '#4DD08B',
      settings: {
        head: null,
        tail: null,
        incomingPath: null,
      },
      output: {},
      output_type: null,
      icon: 'mdi-arrow-down-bold',
    },
    {
      type_id: '06295aaf-9571-4015-ab28-18046fb3f865',
      action_type: 'workflow_input',
      display_name: 'Workflow Input',
      name: 'Workflow Input',
      descr: '',
      default: true,
      input: {},
      color: '#4DD08B',
      settings: {
        head: null,
        tail: null,
        incomingPath: null,
      },
      output: {},
      output_type: null,
      icon: 'mdi-arrow-down-bold',
    },
    {
      type_id: '6cfc15f0-66fe-4a63-ac94-2c523f45103e',
      action_type: 'workflow_output',
      display_name: 'Workflow Output',
      name: 'Workflow Output',
      descr: '',
      default: true,
      input: {},
      color: '#4DD08B',
      settings: {
        head: null,
        tail: null,
        incomingPath: null,
      },
      output: {},
      output_type: null,
      icon: 'mdi-arrow-down-bold',
    },
    {
      type_id: 'c5f7a1f8-e9d3-490b-abad-339c9b935693',
      action_type: 'run_workflow',
      display_name: 'Run Workflow',
      name: 'Run Workflow',
      descr: 'Run Workflow',
      color: '#602AA6',
      icon: 'run_workflow',
      svgIcon: true,
      subject: 'getter',
      output: {},
      output_type: null,
      settings: {
        head: null,
        tail: null,
      },
      input: {
        inputNodeId: null,
        inputFieldId: null,
        inputFieldName: null,
        inputFieldPath: null,
      },
    },
    {
      type_id: '9289274a-4fed-4558-96e9-6048be36af34',
      subject: 'core',
      action_type: 'conditional',
      display_name: 'Conditional',
      name: 'If (...) then',
      descr: 'Add conditional logic with different paths based on condition',
      color: '#602AA6',
      input: {
        inputNodeId: null,
        inputFieldId: null,
        inputFieldName: null,
        inputFieldPath: null,
      },
      settings: {
        head: null,
        tail: null,
        condition: [],
        true: [],
        false: [],
      },
      output_type: null,
      output_external: {},
      icon: 'mdi-source-branch',
    },
    {
      type_id: '11ba841f-505b-4d74-980b-2507a9ee9c01',
      subject: 'core',
      action_type: 'loop',
      display_name: 'Loop',
      name: 'Loop',
      descr: 'Repeat logic for each item in the list',
      color: '#602AA6',
      input: {
        inputNodeId: null,
        inputFieldId: null,
        inputFieldName: null,
        inputFieldPath: null,
      },
      outputs: [
        {
          inputNodeId: null,
          inputFieldId: null,
          inputFieldName: null,
          inputFieldPath: null,
        },
      ],
      settings: {
        head: null,
        tail: null,
        cycleBody: [],
      },
      output_type: null,
      output_external: {},
      icon: 'mdi-sync',
    },
    {
      /**
       * this action is a dummy way to allow us define loop connector output
       * without changing the way loop connector looks.
       * DO NOT MODIFY unless you know what you're doing.
       */
      type_id: '11ba847f-505b-4d74-480b-2507a9ee9c01',
      action_type: 'loop_output',
    },
    {
      /**
       * this action is a dummy way to allow us define conditional connector output
       * without changing the way conditional connector looks.
       * DO NOT MODIFY unless you know what you're doing.
       */
      type_id: '11ba847f-505b-4d74-480b-2547a9ee9c01',
      action_type: 'conditional_output',
    },
    {
      type_id: '675f9ac7-1a9a-4b30-abad-86816d80836c',
      subject: 'core',
      action_type: 'log',
      display_name: 'Log',
      name: 'Log',
      descr: '',
      color: '#602AA6',
      input: {
        inputNodeId: null,
        inputFieldId: null,
        inputFieldName: null,
        inputFieldPath: null,
      },
      output: [],
      settings: {
        head: null,
        tail: null,
      },
      output_type: null,
      icon: 'mdi-message-text',
    },
    {
      type_id: 'cf4a0925-2ff4-4c71-8dca-4b9cb1ebec0e',
      subject: 'core',
      action_type: 'delay',
      display_name: 'Delay',
      name: 'Time delay',
      descr: 'Time delay',
      color: '#602AA6',
      input: {
        inputNodeId: null,
        inputFieldId: null,
        inputFieldName: null,
        inputFieldPath: null,
      },
      output: [],
      settings: {
        head: null,
        tail: null,
      },
      output_type: null,
      icon: 'mdi-alarm',
    },
    {
      type_id: '4611621e-7b6d-485a-9305-ce19a46df582',
      action_type: 'object_helper',
      display_name: 'Object Helper',
      name: 'Object Helper',
      descr: 'Object helper',
      color: '#602AA6',
      icon: 'mdi-format-list-bulleted',
      subject: 'getter',
      output: {},
      output_type: null,
      settings: {
        head: null,
        tail: null,
      },
      inputs: [
        {
          inputNodeId: null,
          inputFieldId: null,
          inputFieldName: null,
          inputFieldPath: null,
        },
      ],
    },
    {
      type_id: '5421d44e-f3fc-40ea-9325-e303de6001c2',
      name: 'List Helper',
      descr: 'List helper',
      color: '#602AA6',
      icon: 'mdi-format-list-numbers',
      subject: 'getter',
      output: {},
      output_type: null,
      settings: {
        head: null,
        tail: null,
      },
      input: {
        inputNodeId: null,
        inputFieldId: null,
        inputFieldName: null,
        inputFieldPath: null,
      },
      actions: [
        {
          type_id: 'e33d50e7-f575-425d-ab9d-1e88aaf41b78',
          subject: 'getter',
          action_type: 'list_filter',
          display_name: 'List Filter',
          name: 'List Filter',
          output: {},
          icon: 'mdi-filter-outline',
          color: '#602AA6',
        },
      ],
    },
    {
      type_id: '8e570931-411c-46a6-ad69-79448e89ac28',
      subject: 'getter',
      name: 'File Helpers',
      descr: 'File manipulation and conversion',
      icon: 'mdi-file',
      color: '#602AA6',
      settings: {
        head: null,
        tail: null,
      },
      input: {
        inputNodeId: null,
        inputFieldId: null,
        inputFieldName: null,
        inputFieldPath: null,
      },
      output_type: null,
      actions: [
        {
          type_id: '57fb8987-0041-4623-94f4-0d1705ea0064',
          subject: 'getter',
          action_type: 'to_csv',
          display_name: 'To CSV',
          name: 'JSON to CSV',
          output: {},
          icon: 'mdi-file',
        },
        {
          type_id: 'c3056816-61b9-4321-bd84-2bbf00d5a752',
          subject: 'getter',
          action_type: 'csv_to_json',
          settings: {
            delimiter: ',',
          },
          display_name: 'CSV to JSON',
          name: 'CSV to JSON',
          output: {},
          icon: 'mdi-code-braces',
        },
        {
          type_id: '9a743a05-1964-45a1-ae63-51790ce0d0cb',
          subject: 'getter',
          action_type: 'xlsx_to_json',
          display_name: 'xlsx to JSON',
          name: 'Excel to JSON',
          output: {},
          icon: 'mdi-code-braces',
        },
        {
          type_id: '0edc5e48-df01-4296-aae2-4b1eadf58645',
          subject: 'getter',
          action_type: 'compress_pdf',
          display_name: 'Compress PDF',
          name: 'Compress PDF File',
          output: {},
          icon: 'mdi-code-braces',
        },
      ],
    },
    {
      type_id: '4bbf4d70-202b-4caa-900f-537c7b520cc8',
      subject: 'core',
      action_type: 'human_in_the_loop',
      display_name: 'Human In the Loop',
      name: 'Human In The Loop',
      descr: 'Prompt the user to provide data',
      icon: 'mdi-pencil',
      color: '#602AA6',
      settings: {
        head: null,
        tail: null,
      },
      input: {
        inputNodeId: null,
        inputFieldId: null,
        inputFieldName: null,
        inputFieldPath: null,
      },
      output_type: null,
      output: {},
    },
    {
      type_id: '0e0a06ec-de07-11ea-87d0-0242ac130003',
      name: 'Date Time',
      descr: 'Convert Date or Time input to desired formats.',
      subject: 'datetime',
      icon: 'mdi-calendar',
      color: '#602AA6',
      action_type: null,
      input: {
        inputNodeId: null,
        inputFieldId: null,
        inputFieldName: null,
        inputFieldPath: null,
      },
      settings: {
        head: null,
        tail: null,
      },
      output_type: null,
      actions: [
        {
          type_id: '0e0a07aa-de07-11ea-87d0-0242ac130003',
          subject: 'datetime',
          action_type: 'format_date_time',
          display_name: 'Format DateTime',
          name: 'Format Date Time',
          output: {},
          icon: 'mdi-calendar-clock',
        },
        {
          type_id: '43f61d3a-0c24-49f4-b045-615eb8eacf2d',
          subject: 'datetime',
          action_type: 'current_date_time',
          display_name: 'Current DateTime',
          name: 'Get the current date and time',
          output: {},
          icon: 'mdi-calendar-clock',
        },
        {
          type_id: '0e0a062e-de07-11ea-87d0-0242ac130003',
          subject: 'datetime',
          action_type: 'get_days_between',
          display_name: 'Get Days Between Dates',
          name: 'Get the number of days between two date values',
          output: {},
          icon: 'mdi-calendar-clock',
        },
      ],
    },
    {
      type_id: '28cf594e-61e7-4782-b062-6b3b75ddbdf7',
      name: name,
      descr: `Connect to a ${name} instance`,
      icon: 'mdi-hospital-box',
      color: '#F42163',
      subject: 'getter',
      action_type: null,
      input: {
        inputNodeId: null,
        inputFieldId: null,
        inputFieldName: null,
        inputFieldPath: null,
      },
      settings: {
        head: null,
        tail: null,
        // + dynamic settings depending on selected action
      },
      actions: [
        {
          type_id: '64c7107b-6695-4c8d-97c3-a4ad10865b07',
          subject: name,
          action_type: 'get_scheduled',
          display_name: 'Get Scheduled',
          name: 'Get scheduled patients',
          output: [],
          output_type: null,
          icon: 'mdi-arrow-down-bold',
        },
        {
          type_id: '5dff107b-61dd-4c8d-97c3-a4ad10865b11',
          subject: name,
          action_type: 'get_walkin_visits',
          display_name: 'Get Walk-in Visits',
          name: 'Get walk-in visits',
          output: [],
          output_type: null,
          icon: 'mdi-arrow-down-bold',
        },
        {
          type_id: '48534010-19f1-4b01-a4e4-c73352f0625a',
          subject: name,
          action_type: 'get_patients_with_balances',
          display_name: 'Get Patients with Balances',
          name: 'Get patients with balances',
          output: [],
          output_type: null,
          icon: 'mdi-arrow-down-bold',
        },
        {
          type_id: 'zf494b93-fae5-6b64-9476-eda4d9xc78a1',
          subject: name,
          action_type: 'insert_update_patient',
          display_name: 'Insert Update Patient',
          name: 'Create or update patient record',
          output: [],
          output_type: null,
          icon: 'mdi-arrow-down-bold',
        },
        {
          type_id: 'dd4b6b93-fae5-4a64-9476-eda4d941ce31',
          subject: name,
          action_type: 'get_patient_messages',
          display_name: 'Get Patient Messages',
          name: 'Get patient messages',
          output: [],
          output_type: null,
          icon: 'mdi-arrow-down-bold',
        },
        {
          type_id: 'cd2f3588-6553-4dec-9547-7ed237649f62',
          subject: name,
          action_type: 'get_patient_insurance',
          display_name: 'Get Patient Insurance',
          name: 'Get patient insurance',
          output: [],
          output_type: null,
          icon: 'mdi-arrow-down-bold',
        },
        {
          type_id: '202d16c5-8ee0-4ed2-89e4-6c6b84e36785',
          subject: name,
          action_type: 'update_balances_notification_date',
          display_name: 'Update Balances Notification Date',
          name: 'Update balances notification date',
          output: null,
          icon: 'mdi-arrow-down-bold',
        },
        {
          type_id: 'd0cecf39-0e26-4fe8-84b9-d5248a88685e',
          subject: name,
          action_type: 'update_custom_field',
          display_name: 'Update Custom Field',
          name: 'Update custom field',
          output: null,
          icon: 'mdi-arrow-down-bold',
        },
        {
          type_id: 'f715a09c-8870-49a6-9dca-7c06649d3759',
          subject: name,
          action_type: 'patient_flag',
          display_name: 'Patient Flag',
          name: 'Patient flag',
          output: null,
          icon: 'mdi-arrow-down-bold',
        },
        {
          type_id: '59432f35-1640-487b-ad03-0008d12e8768',
          subject: name,
          action_type: 'estimate_patient_cost',
          display_name: 'Estimate Patient Cost',
          name: 'Estimate patient cost',
          output: null,
          icon: 'mdi-arrow-down-bold',
        },
        {
          type_id: '124v2f35-1640-487b-ad03-0008d12e8128',
          subject: name,
          action_type: 'update_insurance_record',
          display_name: 'Update Insurance Record',
          name: 'Update patient insurance record',
          output: null,
          icon: 'mdi-arrow-down-bold',
          settings: {
            pid: null,
            params: {},
          },
        },
      ],
    },
    {
      type_id: '47208cd3-d163-4301-a79a-77bfa23960cd',
      subject: 'notify',
      name: 'SMS',
      descr: 'Send sms messages to phone numbers via Twilio',
      icon: 'mdi-bell-ring',
      color: '#F42163',
      action_type: null,
      settings: {
        head: null,
        tail: null,
      },
      input: {
        inputNodeId: null,
        inputFieldId: null,
        inputFieldName: null,
        inputFieldPath: null,
      },
      actions: [
        {
          type_id: '26d2346f-e126-463a-ba8b-24be810381f3',
          subject: 'notify',
          action_type: 'send_sms',
          display_name: 'Send SMS',
          name: 'Send SMS',
          output: null,
          icon: 'mdi-message-processing',
        },
      ],
    },
    {
      type_id: '516cdf96-4d3d-44cc-902c-d7dfb851937f',
      subject: 'notify',
      descr: 'Send an email using AWS sender',
      name: 'Email',
      color: '#F42163',
      icon: 'mdi-email',
      action_type: null,
      settings: {
        head: null,
        tail: null,
      },
      input: {
        inputNodeId: null,
        inputFieldId: null,
        inputFieldName: null,
        inputFieldPath: null,
      },
      actions: [
        {
          type_id: '35b9fdd3-e4b7-4fd8-8baa-f17444af1416',
          subject: 'notify',
          action_type: 'send_email_html',
          display_name: 'Send Email with custom HTML body',
          name: 'Send Email with custom HTML body',
          output: null,
          icon: 'mdi-message-processing',
        },
      ],
    },
    {
      type_id: 'a77d2cb4-2b25-422d-a133-589795a87263',
      name: 'Eligibility',
      descr: 'Request realtime eligibility data from Ribbon Health',
      subject: 'getter',
      icon: 'mdi-hospital-box',
      color: '#F42163',
      action_type: null,
      input: {
        inputNodeId: null,
        inputFieldId: null,
        inputFieldName: null,
        inputFieldPath: null,
      },
      settings: {
        head: null,
        tail: null,
        codes: [],
      },
      actions: [
        {
          type_id: '1b827e2c-914d-4d45-b545-f0465c05ffbd',
          subject: 'ribbon',
          action_type: 'get_recent_ribbons',
          display_name: 'Get Recent Ribbons',
          name: 'Get recent ribbons',
          output: [],
          output_type: null,
          icon: 'mdi-arrow-down-bold',
        },
        {
          type_id: '9e67f9b0-c229-4269-9c37-d0f89fe9862c',
          subject: 'ribbon',
          action_type: 'update_patient_insurance',
          display_name: 'Update Patient Insurance',
          name: 'Update patient benefits',
          output: {},
          output_type: null,
          icon: 'mdi-reload',
        },
        {
          type_id: '44780eca-763c-4180-b4db-1bad017b56a1',
          subject: 'ribbon',
          action_type: 'get_insurance_benefits',
          display_name: 'Get Patient Benefits',
          name: 'Get patient benefits',
          output: {},
          output_type: null,
          icon: 'mdi-reload',
        },
      ],
    },
    {
      type_id: 'a77d2cb4-2b25-422d-a133-589135a87263',
      name: 'pVerify',
      descr: 'Request realtime eligibility data from pVerify API',
      subject: 'getter',
      icon: 'mdi-hospital-box',
      color: '#F42163',
      action_type: null,
      input: {
        inputNodeId: null,
        inputFieldId: null,
        inputFieldName: null,
        inputFieldPath: null,
      },
      settings: {
        head: null,
        tail: null,
        codes: [],
      },
      actions: [
        {
          type_id: '6e47d483-29a9-47ad-8686-ff72258dc65b',
          subject: 'ribbon',
          action_type: 'get_benefits_pverify',
          display_name: 'Get Patient Benefits (pVerify)',
          name: 'Get patient benefits (pVerify)',
          output: {},
          output_type: null,
          icon: 'mdi-reload',
        },
        {
          type_id: '4d77cca9-6fbe-455f-8d23-d78d71f0a51c',
          subject: 'ribbon',
          action_type: 'eligibiltiy_pdf_report_pverify',
          display_name: 'Get Eligibility PDF Report (pVerify)',
          name: 'Get Eligibility PDF Report (pVerify)',
          output: {},
          output_type: null,
          icon: 'mdi-file-pdf',
        },
        {
          type_id: 'de24f206-bbbe-4f1c-8e03-7b06ecc95311',
          subject: 'ribbon',
          action_type: 'parse_benefits_pverify',
          display_name: 'Parse Benefits (pVerify)',
          name: 'Parse benefits (pVerify)',
          output: {},
          output_type: null,
          icon: 'mdi-reload',
        },
        {
          type_id: '8e6298a0-72fa-4b17-82bd-c46b2ef05536',
          subject: 'ribbon',
          action_type: 'get_inquiry_pverify',
          display_name: 'Get Eligibility Inquiry (pVerify)',
          name: 'Get eligibility inquiry (pVerify)',
          output: {},
          output_type: null,
          icon: 'mdi-reload',
        },
      ],
    },
    {
      type_id: '48e937c0-7368-4cbc-a77e-efad0a077561',
      action_type: 'estimate_patient_cost_params',
      display_name: 'Cost Estimator',
      name: 'Cost estimator',
      output: null,
      icon: 'mdi-cash-usd-outline',
      descr: 'Estimate patient cost with custom params',
      color: '#F42163',
      output_type: null,
      settings: {
        head: null,
        tail: null,
      },
      input: {
        inputNodeId: null,
        inputFieldId: null,
        inputFieldName: null,
        inputFieldPath: null,
      },
    },
    {
      type_id: '4c3c631b-ac98-479f-9617-bded986fd27f',
      action_type: 'execute_raw_query',
      display_name: 'Execute SQL',
      name: 'SQL',
      descr: 'Execute sql scripts on a database connection',
      color: '#F42163',
      icon: 'mdi-database',
      subject: 'getter',
      output: {},
      output_type: null,
      settings: {
        head: null,
        tail: null,
      },
      input: {
        inputNodeId: null,
        inputFieldId: null,
        inputFieldName: null,
        inputFieldPath: null,
      },
    },
    {
      type_id: '16162746-4ab8-4126-b508-fcf00276365e',
      action_type: 'execute_javascript',
      display_name: 'Execute JavaScript',
      name: 'JavaScript',
      descr: 'Execute a JavaScript script',
      color: '#F42163',
      icon: 'mdi-nodejs',
      subject: 'getter',
      output: {},
      output_type: null,
      settings: {
        head: null,
        tail: null,
      },
      input: {
        inputNodeId: null,
        inputFieldId: null,
        inputFieldName: null,
        inputFieldPath: null,
      },
    },
    {
      type_id: 'a6756de4-dda1-443e-bcbc-f28552437552',
      display_name: 'PDF Actions',
      name: 'PDF Actions',
      descr: 'Execute a JavaScript script with PDF fill',
      color: '#F42163',
      icon: 'mdi-nodejs',
      subject: 'getter',
      output: {},
      output_type: null,
      actions: [
        {
          type_id: 'd00d6486-778e-4d07-91e6-f822da4d0d84',
          subject: 'getter',
          action_type: 'js_pdf_fill',
          display_name: 'Fill PDF with JavaScript',
          name: 'PDF fill JavaScript',
          output: null,
          icon: 'mdi-nodejs',
          settings: {
            head: null,
            tail: null,
          },
        },
        {
          type_id: '9202fddf-301d-4929-902f-0d9005eb805c',
          subject: 'getter',
          action_type: 'pdf_split_file',
          display_name: 'Split PDF File',
          name: 'Split PDF File',
          output: null,
          icon: 'mdi-file-pdf',
          settings: {
            head: null,
            tail: null,
            base64File: null,
            startPageNumbers: null,
          },
        },
      ],
      input: {
        inputNodeId: null,
        inputFieldId: null,
        inputFieldName: null,
        inputFieldPath: null,
      },
    },
    {
      type_id: 'd84c6da2-f718-414c-a6ed-7f08e5ed17ff',
      name: 'Browser Automation',
      output: null,
      icon: 'mdi-google-chrome',
      descr: '',
      color: '#F42163',
      output_type: null,
      actions: [
        {
          type_id: '0e09fc92-de07-11ea-87d0-0242ac130003',
          subject: 'browser',
          action_type: 'browser_auto',
          display_name: 'Browser Automation',
          name: 'Browser Automation',
          output: null,
          icon: 'mdi-google-chrome',
        },
        {
          type_id: '8e570931-411c-46a6-ad69-79448e89ac28',
          subject: 'browser',
          action_type: 'raw_browser_auto',
          display_name: 'Browser Automation (raw JS)',
          name: 'Browser Automation (raw JS)',
          output: null,
          icon: 'mdi-google-chrome',
        },
        {
          type_id: '3559cec9-4a9b-499c-ad2b-4d4f48a4244e',
          subject: 'browser',
          action_type: 'browser_worker',
          display_name: 'Browser Worker',
          name: 'Browser Worker',
          descr: 'Browser worker actions for Browser Automation',
          output: null,
          icon: 'mdi-google-chrome',
        },
        {
          type_id: '6657cec9-4a9b-499c-ad2b-4d4f48a4245e',
          subject: 'ai',
          action_type: 'aws_execute_ml_model',
          display_name: 'Execute ML Model',
          name: 'Execute ML Model',
          descr: 'Executing pre-trained model',
          output: null,
          icon: 'genai',
          svgIcon: true,
        },
        {
          type_id: '49ffaeb9-0125-4b61-8b3b-17b37d153dd0',
          subject: 'browser',
          action_type: 'browser_auto_webdriver_js',
          display_name: 'Webdriver JS',
          name: 'Webdriver JS Automation',
          descr: 'RawJS code for Webdriver.io',
          output: null,
          icon: 'mdi-google-chrome',
        },
      ],
      settings: {
        head: null,
        tail: null,
      },
      input: {
        inputNodeId: null,
        inputFieldId: null,
        inputFieldName: null,
        inputFieldPath: null,
      },
    },
    {
      type_id: '6dcfaec4-c6ca-4aeb-bb4d-a02a7e9d1ff2',
      name: 'Browser Automation with record',
      output: null,
      icon: 'mdi-google-chrome',
      descr: '',
      color: '#F42163',
      output_type: null,
      actions: [
        {
          type_id: '37d3624f-2d1a-4422-b34b-e45db3219cc5',
          subject: 'browser_record',
          action_type: 'short_browser_auto_playwright',
          display_name: 'Playwright Browser Automation (Short)',
          name: 'Short Playwright Automation',
          output: null,
          descr: 'Browser automation that take LESS than 10 minutes',
          icon: 'mdi-google-chrome',
        },
        {
          type_id: 'b2b5abf9-fbf0-4913-a9de-6d050aa69877',
          subject: 'browser_record',
          action_type: 'long_browser_auto_playwright',
          display_name: 'Long Playwright Automation',
          name: 'Playwright Browser Automation (Long)',
          output: null,
          descr: 'Browser automation that take MORE than 10 minutes',
          icon: 'mdi-google-chrome',
        },
      ],
      settings: {
        head: null,
        isRecordingOn: true,
        tail: null,
        widthSize: 1280,
        heightSize: 720,
        inputDataPath: null,
        authStatePath: null,
        nestedActions: [],
        isObjectFieldSimpleType: false,
      },
      input: {
        inputNodeId: null,
        inputFieldId: null,
        inputFieldName: null,
        inputFieldPath: null,
      },
      nestedSettings: {
        nestedActionJs: '',
        output: null,
        output_type: null,
      },
    },
    {
      type_id: '46b5997a-d6b4-4549-9933-718f4eece3c3',
      action_type: 'execute_http_request',
      display_name: 'Execute HTTP Request',
      name: 'HTTP Client',
      descr: '',
      output: null,
      icon: 'mdi-web',
      color: '#F42163',
      output_type: null,
      settings: {
        head: null,
        tail: null,
        url: null,
        method: 'GET',
        add_headers: [{ name: 'Content-Type', value: 'application/json' }],
        body: null,
      },
      input: {
        inputNodeId: null,
        inputFieldId: null,
        inputFieldName: null,
        inputFieldPath: null,
      },
    },
    {
      type_id: '0e09ee28-de07-11ea-87d0-0242ac130003',
      name: 'SFTP',
      icon: 'mdi-cloud',
      descr: 'Connect to an SFTP server',
      color: '#F42163',
      output_type: null,
      settings: {
        head: null,
        tail: null,
      },
      input: {
        inputNodeId: null,
        inputFieldId: null,
        inputFieldName: null,
        inputFieldPath: null,
      },
      actions: [
        {
          type_id: '0e09f094-de07-11ea-87d0-0242ac130003',
          subject: 'sftp',
          action_type: 'sftp_upload',
          display_name: 'Upload to an SFTP server',
          name: 'Upload file to SFTP server',
          output: null,
          icon: 'mdi-cloud-upload',
        },
        {
          type_id: '0e09f15c-de07-11ea-87d0-0242ac130003',
          subject: 'sftp',
          action_type: 'sftp_download',
          display_name: 'Download from an SFTP server',
          name: 'Download file from an SFTP server',
          output: null,
          icon: 'mdi-cloud-download',
        },
      ],
    },
    {
      type_id: '430a09b5-2fdb-488e-82b2-721cc33fa7ad',
      name: 'Google Workspace',
      icon: 'google',
      svgIcon: true,
      descr: 'Google Workspace Methods',
      color: '#F42163',
      output_type: null,
      settings: {
        head: null,
        tail: null,
      },
      input: {
        inputNodeId: null,
        inputFieldId: null,
        inputFieldName: null,
        inputFieldPath: null,
      },
      actions: [
        {
          type_id: 'f4cc3924-079e-460d-8186-0560de9c170e',
          subject: 'google',
          action_type: 'google_drive_upload_file',
          display_name: 'Upload to Drive',
          name: 'Google Upload to Drive',
          descr: 'Upload a file to a folder in google drive.',
          output_type: null,
          icon: 'google_drive',
          svgIcon: true,
        },
        {
          type_id: '4b0e34de-c7f3-4a0f-bae5-286f1180009b',
          subject: 'google',
          action_type: 'google_drive_download_file',
          display_name: 'Download from Drive',
          name: 'Google Download from Drive',
          descr: 'Download a file from a folder in google drive.',
          output_type: null,
          icon: 'google_drive',
          svgIcon: true,
        },
        {
          type_id: '1c38d53f-caeb-42f9-8666-cc7907557489',
          subject: 'google',
          action_type: 'google_sheets_read_data',
          display_name: 'Read from Spreadsheet',
          name: 'Google Read Spreadsheet',
          descr: 'Get rows of data from a spreadsheet.',
          output_type: null,
          icon: 'google_sheets',
          svgIcon: true,
        },
        {
          type_id: '50a69f4d-f5f9-4276-a97d-078cbe5fbca8',
          subject: 'google',
          action_type: 'google_sheets_write_data',
          display_name: 'Write to Spreadsheet',
          name: 'Google Write to Spreadsheet',
          descr: 'Add rows of data to a spreadsheet.',
          output_type: null,
          icon: 'google_sheets',
          svgIcon: true,
        },
        {
          type_id: '0c3d0510-8be4-4e68-86c5-7eee221d709a',
          subject: 'google',
          action_type: 'google_gmail_send_email',
          display_name: 'Send Email',
          name: 'Google Send Email',
          descr: 'Send Email from your google account',
          output_type: null,
          icon: 'google_gmail',
          svgIcon: true,
        },
        {
          type_id: 'e192d9d3-ff7f-4777-9691-c602f6f5517d',
          subject: 'google',
          action_type: 'google_gmail_read_email',
          display_name: 'Read Email',
          name: 'Google Read Email',
          descr: 'Rend Email from your google account',
          output_type: null,
          icon: 'google_gmail',
          svgIcon: true,
        },
      ],
    },
    {
      type_id: '7aa7f69b-a760-44be-b3a3-1f645a78bb1e',
      name: 'Microsoft Office 365',
      icon: 'office_365',
      svgIcon: true,
      descr: 'Microsoft Office 365 Methods',
      color: '#4DD08B',
      output_type: null,
      settings: {
        head: null,
        tail: null,
        useShareUrl: false,
      },
      input: {
        inputNodeId: null,
        inputFieldId: null,
        inputFieldName: null,
        inputFieldPath: null,
      },
      actions: [
        {
          type_id: '90c9a882-2a6b-47f1-a60b-7a637b300a88',
          subject: 'outlook',
          action_type: 'msgraph_onedrive_upload_file',
          display_name: 'MSgraph Upload to OneDrive',
          name: 'MSgraph Upload to OneDrive',
          descr: 'Upload file to a folder in OneDrive.',
          output_type: null,
          icon: 'msgraph_onedrive',
          svgIcon: true,
          settings: {
            useShareUrl: false,
            shareUrl: null,
          },
        },
        {
          type_id: 'ba164f8c-a829-4a75-b52e-0b43c9717fb7',
          subject: 'outlook',
          action_type: 'msgraph_onedrive_download_file',
          display_name: 'MSgraph Download from OneDrive',
          name: 'MSgraph Download from OneDrive',
          descr: 'Download file from folder in OneDrive.',
          output_type: null,
          icon: 'msgraph_onedrive',
          svgIcon: true,
          settings: {
            useShareUrl: false,
            shareUrl: null,
          },
        },
        {
          type_id: 'e7e881d4-463a-4026-9615-3757f6532171',
          subject: 'outlook',
          action_type: 'msgraph_workbook_read_data',
          display_name: 'MSgraph Read Worksheet',
          name: 'MSgraph Read Worksheet.',
          descr: 'Get rows of data from a workbook.',
          output_type: null,
          icon: 'msgraph_excel',
          svgIcon: true,
          settings: {
            useShareUrl: false,
            shareUrl: null,
          },
        },
        {
          type_id: 'dc15ce84-92de-4421-b8cd-33976aaec6db',
          subject: 'outlook',
          action_type: 'msgraph_workbook_write_data',
          display_name: 'MSgraph Write to Worksheet',
          name: 'MSgraph Write data to Worksheet',
          descr: 'Write rows of data to a workbook',
          output_type: null,
          icon: 'msgraph_excel',
          svgIcon: true,
          settings: {
            useShareUrl: false,
            shareUrl: null,
          },
        },
        {
          type_id: '69e94a33-27b2-4695-8698-17a6c0b3d1ec',
          subject: 'outlook',
          action_type: 'msgraph_mail_send_email',
          display_name: 'MSgraph Send Email',
          name: 'MSgraph Send Email',
          descr: 'Send Email from your outlook account',
          output_type: null,
          icon: 'msgraph_outlook',
          svgIcon: true,
        },
        {
          type_id: 'f12ac473-3901-4a18-85db-57a9352500b7',
          subject: 'outlook',
          action_type: 'msgraph_mail_read_email',
          display_name: 'MSgraph Read Email',
          name: 'MSgraph Read Email',
          descr: 'Read Email from your outlook account',
          output_type: null,
          icon: 'msgraph_outlook',
          svgIcon: true,
        },
        {
          type_id: 'f212663a-22a0-4ba7-999f-7edf6c4233e1',
          subject: 'outlook',
          action_type: 'msgraph_onedrive_create_folder',
          display_name: 'MSgraph Create Folder on OneDrive',
          name: 'MSgraph Create folder on OneDrive',
          descr: 'Create new folder on OneDrive',
          output_type: null,
          icon: 'msgraph_outlook',
          svgIcon: true,
          settings: {
            useShareUrl: false,
            shareUrl: null,
          },
        },
        {
          type_id: 'fef3803d-3e0f-4334-a8bb-93e246a8c3a1',
          subject: 'outlook',
          action_type: 'msgraph_onedrive_get_folder',
          display_name: 'MSgraph Get Folder on OneDrive',
          name: 'MSgraph Get folder on OneDrive',
          descr: 'Get existed folder on OneDrive',
          output_type: null,
          icon: 'msgraph_outlook',
          svgIcon: true,
          settings: {
            useShareUrl: false,
            shareUrl: null,
          },
        },
        {
          type_id: '3ad3f123-e21f-4eb0-9ef6-e2af0a6e2490',
          subject: 'outlook',
          action_type: 'msgraph_onedrive_move_file',
          display_name: 'MS Graph Move File on OneDrive',
          name: 'MS Graph Move File on OneDrive',
          descr: 'Move file to another folder in OneDrive.',
          output_type: null,
          icon: 'msgraph_onedrive',
          svgIcon: true,
          settings: {
            useShareUrl: false,
            shareUrl: null,
          },
        },
        {
          type_id: 'faa46320-f834-4657-98c9-9557d3ded1ea',
          subject: 'outlook',
          action_type: 'msgraph_workbook_update_cell',
          display_name: 'MS Graph workbook update cell',
          name: 'MS Graph update cell',
          descr: 'Update a single cell in a worksheet.',
          output_type: null,
          icon: 'msgraph_onedrive',
          svgIcon: true,
          settings: {
            useShareUrl: false,
            shareUrl: null,
          },
        },
      ],
    },
    {
      type_id: 'aeaffefe-1163-413b-b346-9f19799b33eb',
      name: 'Twilio',
      icon: 'twilio',
      svgIcon: true,
      descr: 'Twilio Methods',
      color: '#4DD08B',
      output_type: null,
      settings: {
        head: null,
        tail: null,
      },
      input: {
        inputNodeId: null,
        inputFieldId: null,
        inputFieldName: null,
        inputFieldPath: null,
      },
      actions: [
        {
          type_id: '48db3e40-001d-4d23-9585-3b7fdec9d98e',
          subject: 'twilio',
          action_type: 'twilio_send_sms',
          display_name: 'Send SMS',
          name: 'Send SMS',
          descr: 'Send SMS using twilio service.',
          output_type: null,
          icon: 'twilio',
          svgIcon: true,
        },
        {
          type_id: 'feba764f-631d-4275-bc6a-722651a27822',
          subject: 'twilio',
          action_type: 'twilio_list_sms',
          display_name: 'List Messages',
          name: 'List Messages',
          descr: 'List message sent from an account or to a number.',
          output_type: null,
          icon: 'twilio',
          svgIcon: true,
        },
        {
          type_id: '59713b89-e7e3-408c-80d5-97a473d2295f',
          subject: 'twilio',
          action_type: 'twilio_send_email',
          display_name: 'Send Email',
          name: 'Send Email',
          descr: 'Send Email using twilio service.',
          output_type: null,
          icon: 'twilio',
          svgIcon: true,
        },
      ],
    },
    {
      type_id: '2680a1b8-518a-442f-a5bc-c53caa1bda65a',
      name: 'Remote VM Bot',
      subject: 'vm_bot',
      descr: '',
      color: '#00F4FB',
      icon: 'mdi-robot',
      action_type: null,
      settings: {
        head: null,
        tail: null,
      },
      input: {
        inputNodeId: null,
        inputFieldId: null,
        inputFieldName: null,
        inputFieldPath: null,
      },
      actions: [
        {
          type_id: '5c396642-f568-4893-a112-bbb4b3b4d1b6',
          subject: 'vm_bot',
          action_type: 'vm_bot_execute_python_code',
          display_name: 'Execute Python Code',
          name: 'Execute Python Code',
          output: null,
          icon: 'mdi-reload',
        },
        {
          type_id: '20ff8fe7-0962-4511-8e66-0ecbe834647d',
          subject: 'vm_bot',
          action_type: 'update_patient_notes',
          display_name: 'Update Patient Notes',
          name: 'Update patient notes',
          output: null,
          icon: 'mdi-reload',
        },
        {
          type_id: '4303c6d0-22c5-4016-83d1-ec696e5da014',
          subject: 'vm_bot',
          action_type: 'update_patient_notes_and_plan',
          display_name: 'Update Patient Notes and Plan',
          name: 'Update patient notes and plan',
          output: null,
          icon: 'mdi-reload',
        },
        {
          type_id: '879f018c-12d0-49b9-915b-04c07db71e74',
          subject: 'vm_bot',
          action_type: 'update_patient_plan',
          display_name: 'Update Patient Payment Plan',
          name: 'Update patient payment plan',
          output: null,
          icon: 'mdi-reload',
        },
        {
          type_id: '6905932c-adb6-47b8-a9d4-ef8fbb5a15a1',
          subject: 'vm_bot',
          action_type: 'dental_get_scheduled',
          display_name: 'Get Scheduled Patients',
          name: 'Get scheduled patients',
          output: null,
          icon: 'mdi-reload',
        },
        {
          type_id: '83128cc2-7404-425b-a4f5-83ffad76f5fa',
          subject: 'vm_bot',
          action_type: 'dental_update_benefits',
          display_name: 'Update Patient Benefits',
          name: 'Update patient benefits',
          output: null,
          icon: 'mdi-reload',
        },
        {
          type_id: '83128cc2-7404-425b-a4f5-83ffad76f5fc',
          subject: 'vm_bot',
          action_type: 'waco_update_benefits',
          display_name: 'Update Patient Benefits (NextGen)',
          name: 'Update patient benefits (NextGen)',
          output: null,
          icon: 'mdi-reload',
        },
        {
          type_id: '83128cc2-7404-425b-a4f5-83ffad76f5fb',
          subject: 'vm_bot',
          action_type: 'waco_set_alerts',
          display_name: 'Set Patient and Appointment Alerts',
          name: 'Set patient and appointment alerts',
          output: null,
          icon: 'mdi-reload',
        },
        {
          type_id: 'ef0411fa-0c9c-487c-9f22-ffea95441f29',
          subject: 'vm_bot',
          action_type: 'post_rob',
          display_name: 'Post ROB',
          name: 'Post ROB',
          output: null,
          icon: 'mdi-reload',
        },
      ],
    },
    {
      type_id: 'c1c91b21-8ffd-481c-b927-e3950f268350',
      name: 'Tasks',
      subject: 'tasks',
      descr: 'Task Creators',
      color: '#00F4FB',
      icon: 'mdi-robot',
      action_type: null,
      settings: {
        head: null,
        tail: null,
      },
      input: {
        inputNodeId: null,
        inputFieldId: null,
        inputFieldName: null,
        inputFieldPath: null,
      },
      actions: [
        {
          type_id: '1be64ad1-1802-49b7-8041-95ca622f1fc1',
          subject: 'tasks',
          action_type: 'task_update_patient_insurance',
          display_name: 'Update Patient Insurance',
          name: 'Update Patient Insurance',
          output: null,
          icon: 'mdi-reload',
        },
        {
          type_id: 'e04bc58d-f26e-4aa8-a7db-1933100032d8',
          subject: 'tasks',
          action_type: 'patient_insurance_form',
          display_name: 'Patient Insurance Form',
          name: 'Patient Insurance Form',
          output: null,
          icon: 'mdi-reload',
        },
      ],
    },
    {
      type_id: '1994fe3b-b7c7-477b-af7e-4c8019fd0e93',
      subject: 'aws',
      action_type: 'aws_claude_prompt',
      display_name: 'AWS Claude',
      name: 'AWS Claude',
      descr: 'Construct and send prompt to AWS Claude',
      color: '#FF9900',
      input: {
        inputNodeId: null,
        inputFieldId: null,
        inputFieldName: null,
        inputFieldPath: null,
      },
      settings: {
        promptInputs: [],
        promptTemplate: '',
        promptOutputs: [],
        tail: null,
        head: null,
      },
      output: null,
      icon: 'mdi-aws',
    },
    {
      type_id: '693bccef-e0cb-4c41-b6c8-959dc21c14b0',
      subject: 'aws',
      action_type: 'aws_textract',
      display_name: 'AWS Textract',
      name: 'AWS Textract',
      descr: 'AWS OCR for files',
      color: '#FF9900',
      input: {
        inputNodeId: null,
        inputFieldId: null,
        inputFieldName: null,
        inputFieldPath: null,
      },
      settings: {
        fileBase64: '',
        tail: null,
        head: null,
      },
      output: null,
      icon: 'mdi-aws',
    },
    {
      type_id: '69dac34a-b1b5-4ca4-ae11-a2aa7e68139d',
      subject: 'ai',
      name: 'Artificial Intelligence',
      descr: '',
      color: '#FF9900',
      input: {
        inputNodeId: null,
        inputFieldId: null,
        inputFieldName: null,
        inputFieldPath: null,
      },
      settings: {
        tail: null,
        head: null,
      },
      actions: [
        {
          type_id: 'e9735b4c-d15b-4196-9f57-a8a318034257',
          subject: 'ai',
          action_type: 'gen_ai_prompt',
          display_name: 'GenAI',
          name: 'GenAI',
          output: null,
          descr: 'Construct and send prompt to GenAI',
          settings: {
            promptInputs: [],
            promptTemplate: '',
            promptOutputs: [],
          },
          icon: 'genai',
          svgIcon: true,
        },
        {
          type_id: '425ee126-9cd6-46fd-9c51-42d010d90310',
          subject: 'ai',
          action_type: 'ai_text_extract',
          display_name: 'OCR Text Extraction',
          name: 'Text Extraction',
          output: null,
          descr: 'Extract text from images or PDF using AI',
          settings: {
            fileBase64: '',
          },
          icon: 'genai',
          svgIcon: true,
        },
      ],
      output: null,
    },
    {
      type_id: '48a91468-190b-40ea-a8f4-dd868f56b503',
      subject: 'aws',
      action_type: 'aws_s3_upload',
      display_name: 'Upload to S3',
      name: 'AWS S3 Upload',
      descr: 'AWS OCR for files',
      color: '#FF9900',
      input: {
        inputNodeId: null,
        inputFieldId: null,
        inputFieldName: null,
        inputFieldPath: null,
      },
      settings: {
        fileBase64: '',
        metadata: null,
        folder: null,
      },
      output: null,
      icon: 'mdi-aws',
    },
    {
      type_id: 'acf440ea-356b-4575-ad70-137e8101681a',
      subject: 'LLM',
      action_type: 'llm_code_generation_icd',
      display_name: 'AI Code Generation ICD',
      name: 'AI Code Generation ICD',
      descr: 'AI Code Generation ICD',
      color: '#FF9900',
      input: {
        inputNodeId: null,
        inputFieldId: null,
        inputFieldName: null,
        inputFieldPath: null,
      },
      settings: {
        file: '',
      },
      output: null,
      icon: 'mdi-star-circle',
    },
    {
      type_id: 'acf450ea-356b-4575-ad70-137e8101670b',
      subject: 'LLM',
      action_type: 'llm_code_generation_cpt',
      display_name: 'AI Code Generation CPT',
      name: 'AI Code Generation CPT',
      descr: 'AI Code Generation CPT',
      color: '#FF9900',
      input: {
        inputNodeId: null,
        inputFieldId: null,
        inputFieldName: null,
        inputFieldPath: null,
      },
      settings: {
        file: '',
      },
      output: null,
      icon: 'mdi-star-circle',
    },
    {
      type_id: '4ee1640f-6fbb-488f-b681-2e08a36127e3',
      name: 'Patient',
      subject: 'patient',
      descr: 'Patient Data',
      color: '#00F4FB',
      icon: 'mdi-account-circle',
      action_type: null,
      settings: {
        head: null,
        tail: null,
      },
      input: {
        inputNodeId: null,
        inputFieldId: null,
        inputFieldName: null,
        inputFieldPath: null,
      },
      actions: [
        {
          type_id: '537ebfd5-8948-4533-8cd9-8249dd9d4149',
          subject: 'patient',
          action_type: 'patient_create_data',
          display_name: 'Create Patient',
          name: 'Create Patient',
          output: null,
          icon: 'mdi-account-plus',
        },
        {
          type_id: 'b49f72db-72de-4604-aeda-e6364129738b',
          subject: 'patient',
          action_type: 'patient_create_invoice',
          display_name: 'Create Patient Invoice',
          name: 'Create patient invoice',
          output: null,
          icon: 'mdi-receipt',
        },
        {
          type_id: 'd1cec6ec-edbf-466e-abef-d8128d54ef87',
          subject: 'patient',
          action_type: 'patient_update_charge',
          display_name: 'Update Patient Charge',
          name: 'Update patient charge',
          output: null,
          icon: 'mdi-receipt',
        },
        {
          type_id: '624b0cb9-567d-47d5-88c7-8949d11d481b',
          subject: 'patient',
          action_type: 'patient_get_charges',
          display_name: 'Get Patient Charges',
          name: 'Get patient charges',
          output: null,
          icon: 'mdi-receipt',
        },
        {
          type_id: '97ad7bfa-eb0c-4c7f-81c0-5f1a31d98802',
          subject: 'patient',
          action_type: 'patient_get_patients_charges',
          display_name: 'Get Patients with Charges',
          name: 'Get Patients with Charges',
          output: null,
          icon: 'mdi-receipt',
          descr: 'Get patients with their charges',
        },
        {
          type_id: '2f7038b8-8e40-4463-b48c-7dce3134542a',
          subject: 'patient',
          action_type: 'patient_reset_balances',
          display_name: 'Reset Patient Balances to Zero',
          name: 'Reset Patients Balances to Zero',
          output: null,
          icon: 'mdi-receipt',
        },
      ],
    },
    {
      type_id: '6e919ad9-8b48-460d-958c-740f3e2e91c6',
      subject: 'core',
      action_type: 'try_catch',
      display_name: 'Try Catch',
      name: 'Try Catch',
      descr: 'Retry connectors on error',
      color: '#602AA6',
      input: {
        inputNodeId: null,
        inputFieldId: null,
        inputFieldName: null,
        inputFieldPath: null,
      },
      output: {},
      output_type: null,
      settings: {
        head: null,
        tail: null,
        retryAttempts: 3,
        retryDelaySeconds: 3,
        filterMessages: [],
        tryCatchBody: [],
        failWorkflow: true,
      },
      //it can be html or link or plain string
      docs: `Limitations: 1) Try catch doesn't return `,
      icon: 'mdi-sync',
    },
  ];
};

export const allowedNestedActionTypes = [
  'long_browser_auto_playwright',
  'short_browser_auto_playwright',
];

export const nestedActionTypes = (nestedActionTypesName) => {
  if (!nestedActionTypesName)
    nestedActionTypesName = ['msgraph_onedrive_upload_file', 'twilio_list_sms'];

  //include actions from actionsTypes if the action_type is in nestedActionTypesName, if the action is nested, include only nested, but safe the parent action
  const nestedActionTypes = actionsTypes().filter((action) => {
    if (
      action.action_type &&
      nestedActionTypesName.includes(action.action_type)
    ) {
      return true;
    }
    if (action.actions) {
      if (
        action.actions.some((action) =>
          nestedActionTypesName.includes(action.action_type)
        )
      ) {
        return true;
      }
    }
    return false;
  });
  return nestedActionTypes;
};

export const isSelectedNodeIsNestedAction = (nestedAction, actionId) => {
  if (!nestedAction.settings?.nestedActions) {
    return null;
  }
  const nestedActionIds = nestedAction.settings.nestedActions.map(
    (aciton) => aciton.id
  );
  return nestedActionIds.includes(actionId);
};

const keysToOmit = ['actions', 'descr', 'color', 'icon'];

export const findNode = (nodes, id) => {
  let result = null;
  const find = (nodes) => {
    nodes.forEach((n) => {
      if (n.id === id) {
        result = n;
      } else if (n.action_type === 'loop') {
        find(n.settings.cycleBody);
      } else if (n.action_type === 'try_catch') {
        find(n.settings.tryCatchBody);
      } else if (n.action_type === 'conditional') {
        find([...n.settings.true, ...n.settings.false]);
      } else if (allowedNestedActionTypes.includes(n.action_type)) {
        find(n.settings.cycnestedActionsleBody || []);
      }
    });
  };

  find(nodes);
  return result;
};

//todo: try_catch
export const isSelectedNodeADescendantOfLoop = (nodes, nodeId, loopNodeId) => {
  const loopNode = findNode(nodes, loopNodeId);
  const innerNodes = loopNode.settings.cycleBody;

  if (!innerNodes) return false;

  return !!findNode(innerNodes, nodeId);
};

export const isSelectedNodeADescendantOfConditional = (
  nodes,
  nodeId,
  loopNodeId
) => {
  const conditionalNode = findNode(nodes, loopNodeId);
  const trueNodes = conditionalNode.settings.true;
  const falseNodes = conditionalNode.settings.false;

  const isDescendantOfTrueNode = !!trueNodes && !!findNode(trueNodes, nodeId);
  const isDescendantOfFalseNode =
    !!falseNodes && !!findNode(falseNodes, nodeId);

  return isDescendantOfTrueNode || isDescendantOfFalseNode;
};

export const findOutputNode = (node, id) => {
  let result = null;
  const find = (nodeItem) => {
    if (nodeItem.uid === id) {
      result = nodeItem;
    } else if (Array.isArray(nodeItem.value)) {
      Array.from(nodeItem.value).forEach((n) => {
        find(n);
      });
    }
  };

  find(node);
  return result;
};

export const getInputModel = (options) => {
  const { inputNodeId, inputNode, inputAction, nodes, nodeId } = options;
  let inputModel = null;

  if (get(inputAction, 'output_type')) {
    inputModel = inputAction.output_type;
  } else if (
    get(inputNode, 'action_type') === 'loop' &&
    !isSelectedNodeADescendantOfLoop(nodes, nodeId, inputNodeId)
  ) {
    inputModel = inputNode.output_external;
  } else if (
    get(inputNode, 'action_type') === 'conditional' &&
    !isSelectedNodeADescendantOfConditional(nodes, nodeId, inputNodeId)
  ) {
    inputModel = inputNode.output_external;
  } else if (get(inputNode, 'output_type')) {
    inputModel = inputNode.output_type;
  }

  return inputModel;
};

export const getActionColor = (icon) => {
  if (!icon) return null;
  const action = actionsTypes('').find((action) => {
    if (action.icon === icon) {
      return true;
    }
    if (
      action.actions &&
      action.actions.some((innderAction) => innderAction.icon === icon)
    ) {
      return true;
    }
    return false;
  });
  if (!action.color) {
    return null;
  }
  return action.color;
};

export function addUIFields(nodes, updatedActionTypes) {
  const nodesToAddUIFields = [...nodes];

  function iterate(item) {
    if (Array.isArray(item)) {
      forEach(item, iterate);
    } else if (isObject(item)) {
      if (item.parentNodeId) {
        item.parentNode = findNode(nodesToAddUIFields, item.parentNodeId);
        delete item.parentNodeId;
      }

      if (item.type_id) {
        const type = find(item.type_id);
        if (!type) return;
        keysToOmit.forEach((key) => {
          if (type.actions) {
            let action = type.actions.find(
              (a) => a.action_type === item.action_type
            );
            //we got bug when we have similar type_id, we fixed it but forget to change all workflow's actions
            // that were created with that id, next code handle this situation
            if (!action) {
              action = updatedActionTypes.find((action) => {
                if (action.action_type === item.action_type) {
                  return action;
                }
                if (
                  action.actions &&
                  action.actions.some(
                    (innderAction) =>
                      innderAction.action_type === item.action_type
                  )
                ) {
                  return action;
                }
                console.error(
                  `action with action type${item.action_type} wasn't found`
                );
                return null;
              });
            }
            item[key] = action[key];
          } else if (type[key]) {
            item[key] = type[key];
          }
        });
      }

      if (!item.parentNode) {
        forEach(item, (v) => iterate(v));
      }
    }
  }

  function find(id) {
    let result = null;

    function findObj(item) {
      if (Array.isArray(item)) {
        item.forEach((i) => findObj(i));
      } else if (isObject(item)) {
        if (item.type_id === id) {
          result = item;
        } else {
          Object.values(findObj);
        }
      }
    }

    findObj(updatedActionTypes);
    return result;
  }

  iterate(nodesToAddUIFields);
  return nodesToAddUIFields;
}

export function omitUIFields(object) {
  const obj = cloneDeep(object);
  const keysToOmitIndex = keyBy(
    Array.isArray(keysToOmit) ? keysToOmit : [keysToOmit]
  );

  function omitFromObject(obj) {
    return transform(obj, function (result, value, key) {
      if (key in keysToOmitIndex) return;

      // turn parentNode reference to id to write JSON
      if (key === 'parentNode' && value) {
        result['parentNodeId'] = value.id;
      } else {
        result[key] = isObject(value) ? omitFromObject(value) : value;
      }
    });
  }

  return omitFromObject(obj); // return the inner function result
}

export function convertToObject(array) {
  let object = {};
  for (let item of array) {
    object[item.id] = item;
  }
  return object;
}

export function generateTrigger(settings) {
  switch (settings.trigger_type) {
    case 'cron': {
      const period = settings.period;
      if (period === 'custom') {
        return settings.custom;
      }
      const dayMonth = period === 'month' ? settings.day : '*';
      const month = '*';
      const dayWeek =
        period === 'day' || period === 'week' ? settings.week.join(',') : '*';

      return `0 ${parseInt(settings.time_at.split(':')[1], 10)} ${parseInt(
        settings.time_at.split(':')[0],
        10
      )} ${dayMonth} ${month} ${dayWeek}`;
    }
    case 'event':
      return settings.trigger_subtype;
    default:
      return null;
  }
}

export function extractCredentials(nodes) {
  const credentials = [];
  function getCredential(node) {
    if (node.action_type === 'loop') {
      node.settings.cycleBody.forEach(getCredential);
    } else if (node.action_type === 'try_catch') {
      node.settings.tryCatchBody.forEach(getCredential);
    } else if (node.action_type === 'conditional') {
      node.settings.false.forEach(getCredential);
      node.settings.true.forEach(getCredential);
    } else if (allowedNestedActionTypes.includes(node.action_type)) {
      const { authenticationId } = node.settings;
      if (authenticationId && !credentials.includes(authenticationId)) {
        credentials.push(authenticationId);
      }
      if (!node.settings.nestedActions) {
        return;
      }
      node.settings.nestedActions.forEach(getCredential);
    } else {
      const { authenticationId } = node.settings;
      if (authenticationId && !credentials.includes(authenticationId)) {
        credentials.push(authenticationId);
      }
    }
  }

  Object.values(nodes).forEach(getCredential);

  return credentials;
}

function parentIsContainerNode(node) {
  return (
    node.action_type === 'conditional' ||
    node.action_type === 'loop' ||
    node.action_type === 'try_catch'
  );
}

export function recursiveAddNode(
  arr,
  parentList,
  nodeListToAdd,
  parentListIndex = 0
) {
  const { index, branch, nodeId } = parentList[parentListIndex];
  const parentAction = getActionById(arr, nodeId);
  let insertIndex = index;
  let settingsSub = branch || 'cycleBody';
  if (insertIndex >= arr.length && parentIsContainerNode(parentAction)) {
    insertIndex--;
  }
  const obj = arr[insertIndex];

  if (obj.action_type === 'try_catch') {
    settingsSub = 'tryCatchBody';
  }
  if (allowedNestedActionTypes.includes(obj.action_type)) {
    settingsSub = 'nestedActions';
  }
  if (parentListIndex === parentList.length - 1) {
    obj.settings[settingsSub] = nodeListToAdd;
    return arr;
  }

  arr[insertIndex] = {
    ...obj,
    settings: {
      ...obj.settings,
      [settingsSub]: recursiveAddNode(
        obj.settings[settingsSub],
        parentList,
        nodeListToAdd,
        parentListIndex + 1
      ),
    },
  };
  return arr;
}

export function getActionById(actions, actionId) {
  if (!actions) {
    return null;
  }
  let iterableActions = actions;
  if (typeof actions === 'object') {
    iterableActions = Object.values(actions);
  }
  let stack = [...iterableActions];

  while (stack.length > 0) {
    const action = stack.pop();

    if (action.id === actionId) {
      return action;
    }

    if (action.settings.tryCatchBody) {
      stack.push(...action.settings.tryCatchBody);
    }
    if (action.settings.cycleBody) {
      stack.push(...action.settings.cycleBody);
    }
    if (action.settings.true) {
      stack.push(...action.settings.true);
    }
    if (action.settings.false) {
      stack.push(...action.settings.false);
    }
  }

  return null; // Return null if the action with the specified ID is not found.
}
