export default {
  SET_ACTIVITY: (state, payload) => {
    state.activity = payload;
  },
  SET_RUNNING_WORKFLOWS: (state, payload) => {
    state.runningWorkflows = payload;
  },
  SET_FAILED_WORKFLOWS: (state, payload) => {
    state.failedWorkflows = payload;
  },
  SET_ACTIVITY_STATS: (state, { activityId, stats }) => {
    state.activity = state.activity.map(item => {
      if (item.id === activityId) {
        item.stats = stats;
      }

      return item;
    })
  },
  SET_ACTIVITY_LOADING: (state, payload) => {
    state.getActivityLoading = payload;
  },
  SET_ACTIVITY_STAT_LOADING: (state, payload) => {
    state.getActivityStatLoading = payload;
  },
  SET_ACTIVITY_TOTAL_NUMBER: (state, payload) => {
    state.activityTotalNumber = payload;
  },
  SET_ACTIVITY_PAGE: (state, payload) => {
    state.activityPage = payload;
  }
};
