import Vue from 'vue';
import VueRouter from 'vue-router';
import Cookies from 'js-cookie';
import store from '@/store';

const LoginPage = () => import('@/views/public/login.vue');
const NavigationPage = () => import('@/views/layout/navigation.vue');
const ActivityPage = () => import('@/views/activity/activity.vue');
const DevicesPage = () => import('@/views/devices/devices.vue');
const AuthenticationsPage = () =>
  import('@/views/authentications/authentications.vue');
const UserManagementPage = () =>
  import('@/views/user-management/user-management.vue');
const WorkersPage = () => import('@/views/workers/workers.vue');
const PromptsPage = () => import('@/views/promts/prompts-page.vue');
const WorkerDetailsPage = () => import('@/views/worker/worker.vue');
const PromptDetailsPage = () => import('@/views/promt/promt.vue');
const ToolDetailsPage = () => import('@/views/promt/tool/tool.vue');
const AutomationPage = () => import('@/views/automation/automation.vue');
const AutomationDetailsPage = () =>
  import('@/views/automation-details/automation-details.vue');
const PatientsPage = () => import('@/views/patients/patients.vue');
const SettingsPage = () => import('@/views/settings-page/settings.vue');
const BrowserAutomationTutorial = () =>
  import('@/views/BrowserAutomationTutorial/BrowserAutomationTutorial.vue');
const AutomationWorkflowBuilder = () =>
  import('@/views/automation-details/builder/Builder.vue');
const AutomationWorkflowHistory = () =>
  import('@/views/automation-details/history/History.vue');
const PatientsDetails = () =>
  import('@/views/patients/popups/patients-popup/patients-popup.vue');
const AutoPayPage = () => import('@/views/autopay/autopay.vue');
const PaymentsPage = () => import('@/views/payments/payments.vue');
const InvoicesDetailsPage = () =>
  import('@/views/payments/patient-details/patient-details.vue');
const WebhooksPage = () => import('@/views/webhooks/webhooks.vue');
const TwoFactor = () => import('@/views/public/two-factor.vue');
const GeneratedCodesPage = () => import('@/views/generated-codes/generated-codes.vue');
const MLModelsPage = () => import('@/views/ml-models/ml-models.vue')
const MLModelPage = () => import('@/views/ml-models/ml-model/ml-model.vue')
const AIChat = () => import('@/views/ai-chat/ai-chat.vue')

class CustomVueRouter extends VueRouter {
  combineQueryStrings(location) {
    const customer = store.state.customer;
    const activeCustomer = customer?.customer?.customer_id;
    const selectedCustomer = customer?.selected_customer?.customer_id;
    const query = {};
    if (activeCustomer === 0) {
      query.customer_id = selectedCustomer;
    }
    return {
      ...location,
      query: {
        ...(location.query || {}),
        ...query,
      },
    };
  }

  push(location, onComplete, onAbort) {
    const newLocation = this.combineQueryStrings(location);
    super.push(newLocation, onComplete, onAbort);
  }

  replace(location, onComplete, onAbort) {
    const newLocation = this.combineQueryStrings(location);
    super.push(newLocation, onComplete, onAbort);
  }
}

Vue.use(CustomVueRouter);

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
  },
  {
    path: '/two-factor',
    name: 'TwoFactor',
    component: TwoFactor,
  },
  {
    path: '/browser-automation-tutorial',
    name: 'BrowserAutomationTutorial',
    component: BrowserAutomationTutorial,
  },
  {
    path: '/',
    component: NavigationPage,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'Activity',
        component: ActivityPage,
      },
      {
        path: 'autopay',
        name: 'Autopay',
        component: AutoPayPage,
        meta: { shAdmin: true },
      },
      {
        path: 'generated-codes',
        name: 'Generated Codes',
        component: GeneratedCodesPage,
        meta: { shAdmin: true },
      },
      {
        path: 'workers',
        name: 'Workers',
        component: WorkersPage,
      },
      {
        path: 'workers/:workerId',
        name: 'Worker',
        component: WorkerDetailsPage,
      },
      {
        path: 'models',
        name: 'ML Models',
        component: MLModelsPage,
      },
      {
        path: 'models/:modelId',
        name: 'ML Model',
        component: MLModelPage,
      },
      {
        path: 'prompts/:promptId',
        name: 'Prompt',
        component: PromptDetailsPage,
      },
      {
        path: 'prompts-tools/:toolId',
        name: 'Tool',
        component: ToolDetailsPage,
      },
      {
        path: 'payments',
        name: 'Payments',
        component: PaymentsPage,
        meta: { paymentV2: true },
      },
      {
        path: 'payments/patients/:patientId',
        name: 'Patient Payments Details',
        component: InvoicesDetailsPage,
      },
      {
        path: 'devices',
        name: 'Devices',
        component: DevicesPage,
      },
      {
        path: 'user-management',
        name: 'User Management',
        component: UserManagementPage,
        meta: { privilegedRoute: true },
      },
      {
        path: 'integrations',
        name: 'Integrations',
        component: AuthenticationsPage,
        meta: { privilegedRoute: true },
      },
      {
        path: 'workflows',
        name: 'Workflows',
        component: AutomationPage,
      },
      {
        path: 'workflows/:automationId/',
        component: AutomationDetailsPage,
        name: 'WorkflowDetails',
        children: [
          {
            path: 'builder',
            name: 'Builder',
            component: AutomationWorkflowBuilder,
          },
          {
            path: 'history',
            name: 'History',
            component: AutomationWorkflowHistory,
          },
          {
            path: '*',
            redirect: '/builder',
          },
        ],
      },
      {
        path: 'patients',
        name: 'Patients',
        component: PatientsPage,
      },
      {
        path: 'patients/:patientId',
        name: 'Patient',
        component: PatientsDetails,
      },
      {
        path: '/settings',
        name: 'Settings',
        component: SettingsPage,
      },
      {
        path: '/webhooks',
        name: 'Webhooks',
        component: WebhooksPage,
      },
      {
        path: 'prompts',
        name: 'prompts',
        component: PromptsPage,
      },
      {
        path: '/ai-chat',
        name: 'AI Chat',
        component: AIChat,
      },
    ],
  },
];

function isPrivilegedUser(to) {
  return (
    to.matched.some((record) => record.meta.privilegedRoute) &&
    (!Cookies.get('is_privileged') || Cookies.get('is_privileged') === 'false')
  );
}

function isShAdmin(to) {
  return (
    to.matched.some((record) => record.meta.shAdmin) &&
    Cookies.get('customerId') !== '0'
  );
}

function isAllowedCustomer({ matched }) {
  const route = matched.find((record) => record.meta.allowedCustomers);
  const customers = route ? route.meta.allowedCustomers : [];

  if (!route) return false;
  if (!store.state.customer) return true;
  const value = !customers.includes(store.state.customer.customer.customer_id);

  return value;
}

function isPaymentV2Customer(to) {
  return (
    to.matched.some((record) => record.meta.paymentV2) &&
    !store.state.customer?.featureFlags?.payment_v2
  );
}

const router = new CustomVueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !Cookies.get('token')
  ) {
    next({ name: 'login' });
    return;
  } else if (
    isShAdmin(to) ||
    isPaymentV2Customer(to) ||
    isPrivilegedUser(to) ||
    isAllowedCustomer(to)
  ) {
    next({ path: '/' });
  } else {
    next();
  }
});

export default router;
