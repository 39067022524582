const getCurrentSession = async ({ commit, rootGetters, dispatch }, payload) => {
    commit("SET_CURRENT_SESSION_LOADING", true);
    try {
        const customer = rootGetters["customer/CUSTOMER"];
        const selected_customer = rootGetters["customer/SELECTED_CUSTOMER"];
        const data = { ...payload, customer_id: customer.customer_id };

        if (customer.customer_id === 0) {
            data.customer_id = selected_customer.customer_id;
        }

        const response = await window.axios.get(`/proxy/ai/sessions/${payload.sessionId}?customer_id=${data.customer_id}`, data);
        if (response && response.data.success) {
            commit('SET_CURRENT_SESSION', response.data.session)
        } else {
            throw response.data;
        }
    } catch (error) {
        dispatch("notifications/setError", error.message, { root: true });
    } finally {
        commit("SET_CURRENT_SESSION_LOADING", false);
    }
};

export default getCurrentSession;