const fetchWorkflow = async ({ commit, rootGetters, dispatch }, id) => {
  commit('SET_FETCH_AUTOMATION_WORKFLOW_LOADING', true);
  try {
    const customer = rootGetters['customer/CUSTOMER'];
    const selected_customer = rootGetters['customer/SELECTED_CUSTOMER'];

    let url = `/proxy/get-workflows/${id}`;
    let customer_id = customer.customer_id;
    if (customer_id === 0) {
      customer_id = selected_customer.customer_id;
    }

    const data = { customer_id };
    const response = await window.axios.post(url, data);

    if (response && response.data.success) {
      const { workflow } = response.data;
      commit('SET_AUTOMATION_WORKFLOW', workflow);
      commit('SET_ALERT_SETTINGS', workflow.alertSettings);
      commit('SET_S3_STORAGE_ENABLED', workflow.IOStorageType === 's3');
      dispatch('workflowSetup');
    } else {
      throw response.data;
    }
  } catch (error) {
    dispatch('notifications/setError', error.message, { root: true });
  } finally {
    // stuff here
    commit('SET_FETCH_AUTOMATION_WORKFLOW_LOADING', false);
  }
};

export default fetchWorkflow;
